<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info text-center">
        <i class="fa-solid fa-circle-arrow-down"></i>
        Debes agregar la cantidad de <strong>días sin accidentes</strong>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input 
        class="form-control input-custom"
        placeholder="Ingresa un número"
        v-model="diasSinAccidentes" 
        type="text"
        @input="onlyNumeric"
      />
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: {
    loaded_dias: {
      type: String,
    }
  },

  data() {
    return {
      diasSinAccidentes: ""
    };
  },

  watch: {
    diasSinAccidentes(val) {
      this.$emit("setSelectedDias", val)
    }
  },

  created() {
    if (this.loaded_dias) {
      this.diasSinAccidentes = this.loaded_dias;
    }
  },

  methods: {
    onlyNumeric() {
      this.diasSinAccidentes = this.diasSinAccidentes.replace(/[^0-9]/g, '');
    }
  }
};
</script>
